import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'
import Index from '../views/Index.vue'
const Master = () => import(/* webpackChunkName: "Master" */'../views/Master.vue')
const ArticleList = () => import(/* webpackChunkName: "Equiment" */'../views/ArticleList.vue')
const Article = () => import(/* webpackChunkName: "Equiment" */'../views/Article.vue')
const Business = () => import(/* webpackChunkName: "Equiment" */'../views/Business.vue')
const BusinessList = () => import(/* webpackChunkName: "Equiment" */'../views/BusinessList.vue')
const About = () => import(/* webpackChunkName: "Equiment" */'../views/About.vue')
Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: Home
  // },
  {
    path: '/',
    redirect: 'index'
  },
  {
    path: '/master',
    name: 'master',
    component: Master,
    children: [
      {
        path: '/index', name: 'index', component: Index,
      },
      {
        path: '/articlelist', name: 'articlelist', component: ArticleList,
      },
      {
        path: '/article', name: 'article', component: Article,
      },
      {
        path: '/article/:id', name: 'article_id', component: Article,
      },
      {
        path: '/businesslist', name: 'businesslist', component: BusinessList,
      },
      {
        path: '/business', name: 'business', component: Business,
      },
      {
        path: '/business/:id', name: 'business_id', component: Business,
      },
      {
        path: '/about', name: 'about', component: About,
      },
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
