<template>
  <div>




        <!--banner-->
    <div class="ban pc">
        <ul class="imgbox">
            <a href="#/#"><img src="img/9.4banner1172.jpg" alt="广告一" style="display: block;"></a>
            <a href="#/#"><img src="img/b2181.jpg" alt="广告二" style="display: none;"></a>
            <a href="#/#"><img src="img/9.4banner3145.jpg" alt="广告三" style="display: none;"></a>

        </ul>
        <div class="boy commen">
            <ul class="bt-a" style="display: block;">
                <div class="bt-b">
                </div>
            </ul>
            <ul class="bt-k" style="display: block;">
                <div class="bt-f">
                </div>
            </ul>
            <div class="clearfix">
            </div>
        </div>

    </div>
    <!--手机banner-->
    <div class="sjban mob">
        <div class="swiper-container swiper2 swiper-container-horizontal swiper-container-autoheight">
            <div class="swiper-wrapper" style="transition-duration: 600ms;"><div class="swiper-slide swiper-slide-duplicate" data-swiper-slide-index="2"><a href="#/#"><img src="img/9.4sj3181.jpg" alt="广告三"></a></div>
                <div class="swiper-slide" data-swiper-slide-index="0"><a href="#/#"><img src="img/9.4sj1154.jpg" alt="广告一"></a></div>
<div class="swiper-slide" data-swiper-slide-index="1"><a href="#/#"><img src="img/sjb2163.jpg" alt="广告二"></a></div>
<div class="swiper-slide" data-swiper-slide-index="2"><a href="#/#"><img src="img/9.4sj3181.jpg" alt="广告三"></a></div>

            <div class="swiper-slide swiper-slide-duplicate" data-swiper-slide-index="0"><a href="#/#"><img src="img/9.4sj1154.jpg" alt="广告一"></a></div></div>
        <span class="swiper-notification" aria-live="assertive" aria-atomic="true"></span></div>
    </div>

    <!--主营业务-->
    <div class="yw">
        <div class="commen">
            <div class="bt" data-scroll-reveal="enter bottom and move 30px over 0.3s" data-scroll-reveal-id="1" style="-webkit-transform: translatey(0);transform: translatey(0);opacity: 1;-webkit-transition: -webkit-transform 0.3s ease-in-out 0.1s,  opacity 0.3s ease-in-out 0.1s;transition: transform 0.3s ease-in-out 0.1s, opacity 0.3s ease-in-out 0.1s;-webkit-perspective: 1000;-webkit-backface-visibility: hidden;" data-scroll-reveal-initialized="true">
                <img src="img/tt1.png">
            </div>
            <div class="ywa pc">
                <div class="ywb fl" data-scroll-reveal="enter bottom and move 30px over 0.3s" data-scroll-reveal-id="2" style="-webkit-transform: translatey(30px);transform: translatey(30px);opacity: 0;-webkit-transition: -webkit-transform 0.3s ease-in-out 0s,  opacity 0.3s ease-in-out 0.1s;transition: transform 0.3s ease-in-out 0s,  opacity 0.3s ease-in-out 0.1s;-webkit-perspective: 1000;-webkit-backface-visibility: hidden;" data-scroll-reveal-initialized="true">
<a href="#/system/" title="系统集成_系统集成公司_智能化系统集成公司">
<img src="img/y1.jpg" alt="系统集成_系统集成公司_智能化系统集成公司">
<h6><span>系统集成</span>system integration</h6>
<div class="ywb_w">
<div class="ywb_wa fl">
<img src="img/y5.png" alt="基础网络_基础网络开发_基础网络开发公司">
<p>基础网络</p>
</div>
<div class="ywb_wa fl">
<img src="img/y6.png" alt="安防监控_安防监控系统_安防监控管理系统">
<p>安防监控</p>
</div>
<!-- <div class="ywb_wa fl">
<img src="img/y7.png" alt="协助通信_协助通信服务_协助通信服务公司">
<p>协助通信</p>
</div> -->
<div class="ywb_wa fl">
<img src="img/y8.png" alt="多媒体影音_多媒体影音系统_多媒体影音系统公司">
<p>多媒体影音</p>
</div>
<div class="ywb_wa fl">
<img src="img/y9.png" alt="视频会议_视频会议系统_音视频会议系统">
<p>视频会议</p>
</div>
<div class="clearfix"></div>
</div>
</a>
</div>
<div class="ywb fr" data-scroll-reveal="enter bottom and move 30px over 0.3s" data-scroll-reveal-id="3" style="-webkit-transform: translatey(30px);transform: translatey(30px);opacity: 0;-webkit-transition: -webkit-transform 0.3s ease-in-out 0s,  opacity 0.3s ease-in-out 0.1s;transition: transform 0.3s ease-in-out 0s,  opacity 0.3s ease-in-out 0.1s;-webkit-perspective: 1000;-webkit-backface-visibility: hidden;" data-scroll-reveal-initialized="true">
<a href="#/maintenance/" title="IT运维_企业IT运维_企业it运维服务商">
<img src="img/y2.jpg" alt="IT运维_企业IT运维_企业it运维服务商">
<h6><span>IT运维</span>IT Operation &amp; maintenance</h6>
<div class="ywb_w">
<div class="ywb_wa fl">
<img src="img/y10.png" alt="驻场服务_网络驻场服务_网络驻场服务公司">
<p>驻场服务</p>
</div>
<div class="ywb_wa fl">
<img src="img/y11.png" alt="应急服务_网络应急服务_网络应急服务公司">
<p>应急服务</p>
</div>
<div class="ywb_wa fl">
<img src="img/y12.png" alt="远程服务_远程服务系统_远程服务管理系统">
<p>远程服务</p>
</div>
<div class="ywb_wa fl">
<img src="img/y13.png" alt="IT采购_it系统采购_it系统采购公司">
<p>IT采购</p>
</div>
<!-- <div class="ywb_wa fl">
<img src="img/y14.png" alt="IDC巡检_IDC巡检系统_智能IDC巡检系统">
<p>IDC巡检</p>
</div> -->
<div class="clearfix"></div>
</div>
</a>
</div>
<div class="ywb fl" data-scroll-reveal="enter bottom and move 30px over 0.3s" data-scroll-reveal-id="4" style="-webkit-transform: translatey(30px);transform: translatey(30px);opacity: 0;-webkit-transition: -webkit-transform 0.3s ease-in-out 0s,  opacity 0.3s ease-in-out 0.1s;transition: transform 0.3s ease-in-out 0s,  opacity 0.3s ease-in-out 0.1s;-webkit-perspective: 1000;-webkit-backface-visibility: hidden;" data-scroll-reveal-initialized="true">
<a href="#/network/" title="网络安全_网络安全防护_网络安全防护系统">
<img src="img/y3.jpg" alt="网络安全_网络安全防护_网络安全防护系统">
<h6><span>软件开发</span>software development</h6>
<div class="ywb_w">
<!-- <div class="ywb_wa fl">
<img src="img/y15.png" alt="边界安全_网络边界安全_网络边界安全防护">
<p>边界安全</p>
</div> -->
<div class="ywb_wa fl">
<img src="img/y16.png" alt="内网安全_内网安全管理_内网安全管理软件">
<p>软件安全</p>
</div>
<div class="ywb_wa fl">
<img src="img/y17.png" alt="数据安全_数据安全管理_数据安全管理公司">
<p>数据保护</p>
</div>
<div class="ywb_wa fl">
<img src="img/y18.png" alt="云计算安全_云计算网络安全_云计算网络安全公司">
<p>云计算安全</p>
</div>
<div class="ywb_wa fl">
<img src="img/y19.png" alt="安全服务_信息安全服务_信息安全服务公司">
<p>售后服务</p>
</div>
<div class="clearfix"></div>
</div>
</a>
</div>
<div class="ywb fr" data-scroll-reveal="enter bottom and move 30px over 0.3s" data-scroll-reveal-id="5" style="-webkit-transform: translatey(30px);transform: translatey(30px);opacity: 0;-webkit-transition: -webkit-transform 0.3s ease-in-out 0s,  opacity 0.3s ease-in-out 0.1s;transition: transform 0.3s ease-in-out 0s,  opacity 0.3s ease-in-out 0.1s;-webkit-perspective: 1000;-webkit-backface-visibility: hidden;" data-scroll-reveal-initialized="true">
<a href="#/data_center/" title="数据中心_数据中心运维_数据中心运维服务">
<img src="img/y4.jpg" alt="数据中心_数据中心运维_数据中心运维服务">
<h6><span>智慧校园</span>smart campus</h6>
<div class="ywb_w">
<div class="ywb_wa fl">
<img src="img/y20.png" alt="数据中心建设_青岛数据中心建设方案">
<p>校园数据中心建设</p>
</div>
<div class="ywb_wa fl">
<img src="img/y21.png" alt="数据中心管理_青岛数据中心管理软件">
<p>校园数据中心管理</p>
</div>
<div class="ywb_wa fl">
<img src="img/y22.png" alt="关键电源管理_青岛关键电源管理系统">
<p>关键电源管理</p>
</div>
<div class="ywb_wa fl">
<img src="img/y23.png" alt="设备更新及改造_智能化设备更新及改造">
<p>设备更新及改造</p>
</div>
<!-- <div class="ywb_wa fl">
<img src="img/y24.png" alt="异地迁移安置_青岛网络异地迁移安置">
<p>异地迁移安置</p>
</div> -->
<div class="clearfix"></div>
</div>
</a>
</div>

                <div class="clearfix">
                </div>
            </div>
            <div class="ywa mob" data-scroll-reveal="enter bottom and move 30px over 0.3s" data-scroll-reveal-id="6" style="-webkit-transform: translatey(0);transform: translatey(0);opacity: 1;-webkit-transition: -webkit-transform 0.3s ease-in-out 0.1s,  opacity 0.3s ease-in-out 0.1s;transition: transform 0.3s ease-in-out 0.1s, opacity 0.3s ease-in-out 0.1s;-webkit-perspective: 1000;-webkit-backface-visibility: hidden;" data-scroll-reveal-initialized="true">
                <div class="swiper-container swiper-container-horizontal swiper-container-autoheight" id="swiper_yw">
                    <div class="swiper-wrapper" style="transition-duration: 500ms;"><div class="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-active" data-swiper-slide-index="2">
<div class="ywb">
<a href="#/network/" title="网络安全_网络安全防护_网络安全防护系统">
<img src="img/y3.jpg" alt="网络安全_网络安全防护_网络安全防护系统">
<h6><span>网络安全</span>network security</h6>
<div class="ywb_w">
<div class="ywb_wa fl">
<img src="img/y15.png" alt="边界安全_网络边界安全_网络边界安全防护">
<p>边界安全</p>
</div>
<div class="ywb_wa fl">
<img src="img/y16.png" alt="内网安全_内网安全管理_内网安全管理软件">
<p>内网安全</p>
</div>
<div class="ywb_wa fl">
<img src="img/y17.png" alt="数据安全_数据安全管理_数据安全管理公司">
<p>数据安全</p>
</div>
<div class="ywb_wa fl">
<img src="img/y18.png" alt="云计算安全_云计算网络安全_云计算网络安全公司">
<p>云计算安全</p>
</div>
<div class="ywb_wa fl">
<img src="img/y19.png" alt="安全服务_信息安全服务_信息安全服务公司">
<p>安全服务</p>
</div>
<div class="clearfix"></div>
</div>
</a>
</div>
</div><div class="swiper-slide swiper-slide-duplicate" data-swiper-slide-index="3">
<div class="ywb">
<a href="#/data_center/" title="数据中心_数据中心运维_数据中心运维服务">
<img src="img/y4.jpg" alt="数据中心_数据中心运维_数据中心运维服务">
<h6><span>数据中心</span>Data center</h6>
<div class="ywb_w">
<div class="ywb_wa fl">
<img src="img/y20.png" alt="数据中心建设_青岛数据中心建设方案">
<p>数据中心建设</p>
</div>
<div class="ywb_wa fl">
<img src="img/y21.png" alt="数据中心管理_青岛数据中心管理软件">
<p>数据中心管理</p>
</div>
<div class="ywb_wa fl">
<img src="img/y22.png" alt="关键电源管理_青岛关键电源管理系统">
<p>关键电源管理</p>
</div>
<div class="ywb_wa fl">
<img src="img/y23.png" alt="设备更新及改造_智能化设备更新及改造">
<p>设备更新及改造</p>
</div>
<div class="ywb_wa fl">
<img src="img/y24.png" alt="异地迁移安置_青岛网络异地迁移安置">
<p>异地迁移安置</p>
</div>
<div class="clearfix"></div>
</div>
</a>
</div>
</div>
                        <div class="swiper-slide" data-swiper-slide-index="0">
<div class="ywb">
<a href="#/system/" title="系统集成_系统集成公司_智能化系统集成公司">
<img src="img/y1.jpg" alt="系统集成_系统集成公司_智能化系统集成公司">
<h6><span>系统集成</span>system integration</h6>
<div class="ywb_w">
<div class="ywb_wa fl">
<img src="img/y5.png" alt="基础网络_基础网络开发_基础网络开发公司">
<p>基础网络</p>
</div>
<div class="ywb_wa fl">
<img src="img/y6.png" alt="安防监控_安防监控系统_安防监控管理系统">
<p>安防监控</p>
</div>
<div class="ywb_wa fl">
<img src="img/y7.png" alt="协助通信_协助通信服务_协助通信服务公司">
<p>协助通信</p>
</div>
<div class="ywb_wa fl">
<img src="img/y8.png" alt="多媒体影音_多媒体影音系统_多媒体影音系统公司">
<p>多媒体影音</p>
</div>
<div class="ywb_wa fl">
<img src="img/y9.png" alt="视频会议_视频会议系统_音视频会议系统">
<p>视频会议</p>
</div>
<div class="clearfix"></div>
</div>
</a>
</div>
</div>
<div class="swiper-slide" data-swiper-slide-index="1">
<div class="ywb">
<a href="#/maintenance/" title="IT运维_企业IT运维_企业it运维服务商">
<img src="img/y2.jpg" alt="IT运维_企业IT运维_企业it运维服务商">
<h6><span>IT运维</span>IT Operation &amp; maintenance</h6>
<div class="ywb_w">
<div class="ywb_wa fl">
<img src="img/y10.png" alt="驻场服务_网络驻场服务_网络驻场服务公司">
<p>驻场服务</p>
</div>
<div class="ywb_wa fl">
<img src="img/y11.png" alt="应急服务_网络应急服务_网络应急服务公司">
<p>应急服务</p>
</div>
<div class="ywb_wa fl">
<img src="img/y12.png" alt="远程服务_远程服务系统_远程服务管理系统">
<p>远程服务</p>
</div>
<div class="ywb_wa fl">
<img src="img/y13.png" alt="IT采购_it系统采购_it系统采购公司">
<p>IT采购</p>
</div>
<div class="ywb_wa fl">
<img src="img/y14.png" alt="IDC巡检_IDC巡检系统_智能IDC巡检系统">
<p>IDC巡检</p>
</div>
<div class="clearfix"></div>
</div>
</a>
</div>
</div>
<div class="swiper-slide" data-swiper-slide-index="2">
<div class="ywb">
<a href="#/network/" title="网络安全_网络安全防护_网络安全防护系统">
<img src="img/y3.jpg" alt="网络安全_网络安全防护_网络安全防护系统">
<h6><span>网络安全</span>network security</h6>
<div class="ywb_w">
<div class="ywb_wa fl">
<img src="img/y15.png" alt="边界安全_网络边界安全_网络边界安全防护">
<p>边界安全</p>
</div>
<div class="ywb_wa fl">
<img src="img/y16.png" alt="内网安全_内网安全管理_内网安全管理软件">
<p>内网安全</p>
</div>
<div class="ywb_wa fl">
<img src="img/y17.png" alt="数据安全_数据安全管理_数据安全管理公司">
<p>数据安全</p>
</div>
<div class="ywb_wa fl">
<img src="img/y18.png" alt="云计算安全_云计算网络安全_云计算网络安全公司">
<p>云计算安全</p>
</div>
<div class="ywb_wa fl">
<img src="img/y19.png" alt="安全服务_信息安全服务_信息安全服务公司">
<p>安全服务</p>
</div>
<div class="clearfix"></div>
</div>
</a>
</div>
</div>
<div class="swiper-slide" data-swiper-slide-index="3">
<div class="ywb">
<a href="#/data_center/" title="数据中心_数据中心运维_数据中心运维服务">
<img src="img/y4.jpg" alt="数据中心_数据中心运维_数据中心运维服务">
<h6><span>数据中心</span>Data center</h6>
<div class="ywb_w">
<div class="ywb_wa fl">
<img src="img/y20.png" alt="数据中心建设_青岛数据中心建设方案">
<p>数据中心建设</p>
</div>
<div class="ywb_wa fl">
<img src="img/y21.png" alt="数据中心管理_青岛数据中心管理软件">
<p>数据中心管理</p>
</div>
<div class="ywb_wa fl">
<img src="img/y22.png" alt="关键电源管理_青岛关键电源管理系统">
<p>关键电源管理</p>
</div>
<div class="ywb_wa fl">
<img src="img/y23.png" alt="设备更新及改造_智能化设备更新及改造">
<p>设备更新及改造</p>
</div>
<div class="ywb_wa fl">
<img src="img/y24.png" alt="异地迁移安置_青岛网络异地迁移安置">
<p>异地迁移安置</p>
</div>
<div class="clearfix"></div>
</div>
</a>
</div>
</div>

                    <div class="swiper-slide swiper-slide-duplicate" data-swiper-slide-index="0">
<div class="ywb">
<a href="#/system/" title="系统集成_系统集成公司_智能化系统集成公司">
<img src="/img/y1.jpg" alt="系统集成_系统集成公司_智能化系统集成公司">
<h6><span>系统集成</span>system integration</h6>
<div class="ywb_w">
<div class="ywb_wa fl">
<img src="img/y5.png" alt="基础网络_基础网络开发_基础网络开发公司">
<p>基础网络</p>
</div>
<div class="ywb_wa fl">
<img src="img/y6.png" alt="安防监控_安防监控系统_安防监控管理系统">
<p>安防监控</p>
</div>
<div class="ywb_wa fl">
<img src="img/y7.png" alt="协助通信_协助通信服务_协助通信服务公司">
<p>协助通信</p>
</div>
<div class="ywb_wa fl">
<img src="img/y8.png" alt="多媒体影音_多媒体影音系统_多媒体影音系统公司">
<p>多媒体影音</p>
</div>
<div class="ywb_wa fl">
<img src="img/y9.png" alt="视频会议_视频会议系统_音视频会议系统">
<p>视频会议</p>
</div>
<div class="clearfix"></div>
</div>
</a>
</div>
</div><div class="swiper-slide swiper-slide-duplicate" data-swiper-slide-index="1">
<div class="ywb">
<a href="#/maintenance/" title="IT运维_企业IT运维_企业it运维服务商">
<img src="img/y2.jpg" alt="IT运维_企业IT运维_企业it运维服务商">
<h6><span>IT运维</span>IT Operation &amp; maintenance</h6>
<div class="ywb_w">
<div class="ywb_wa fl">
<img src="img/y10.png" alt="驻场服务_网络驻场服务_网络驻场服务公司">
<p>驻场服务</p>
</div>
<div class="ywb_wa fl">
<img src="img/y11.png" alt="应急服务_网络应急服务_网络应急服务公司">
<p>应急服务</p>
</div>
<div class="ywb_wa fl">
<img src="img/y12.png" alt="远程服务_远程服务系统_远程服务管理系统">
<p>远程服务</p>
</div>
<div class="ywb_wa fl">
<img src="img/y13.png" alt="IT采购_it系统采购_it系统采购公司">
<p>IT采购</p>
</div>
<div class="ywb_wa fl">
<img src="img/y14.png" alt="IDC巡检_IDC巡检系统_智能IDC巡检系统">
<p>IDC巡检</p>
</div>
<div class="clearfix"></div>
</div>
</a>
</div>
</div></div>
                <span class="swiper-notification" aria-live="assertive" aria-atomic="true"></span></div>
            </div>
            <!-- <div class="ywa_m" data-scroll-reveal="enter bottom and move 20px over 0.2s" data-scroll-reveal-id="7" style="-webkit-transform: translatey(20px);transform: translatey(20px);opacity: 0;-webkit-transition: -webkit-transform 0.2s ease-in-out 0s,  opacity 0.2s ease-in-out 0.1s;transition: transform 0.2s ease-in-out 0s,  opacity 0.2s ease-in-out 0.1s;-webkit-perspective: 1000;-webkit-backface-visibility: hidden;" data-scroll-reveal-initialized="true">
                <a href="#/system/">查看更多 &gt;</a></div> -->
        </div>
    </div>
    <div class="bk1" data-scroll-reveal="enter bottom and move 30px over 0.3s" data-scroll-reveal-id="8" style="-webkit-transform: translatey(30px);transform: translatey(30px);opacity: 0;-webkit-transition: -webkit-transform 0.3s ease-in-out 0s,  opacity 0.3s ease-in-out 0.1s;transition: transform 0.3s ease-in-out 0s,  opacity 0.3s ease-in-out 0.1s;-webkit-perspective: 1000;-webkit-backface-visibility: hidden;" data-scroll-reveal-initialized="true">
	<div class="commen">
		<div class="bk1a fl">
			<h5>
				专业
			</h5>
			<h6>
				major
			</h6>
			<p>
				专注于数据中心、融合通讯<br>
和智慧建筑等专业领域
			</p>
		</div>
		<div class="bk1a fl bk1ac">
			<h5>
				团队
			</h5>
			<h6>
				team
			</h6>
			<p>
				一支具有专业知识<br>
丰富经验的IT团队
			</p>
		</div>
		<div class="bk1a fl bk1ab">
			<h5>
				经验
			</h5>
			<h6>
				experience
			</h6>
			<p>
				公司成立于2010年<br>
拥有行业内十余年解决方案经验
			</p>
		</div>
		<div class="bk1a fl bk1aa">
			<h5>
				服务
			</h5>
			<h6>
				service
			</h6>
			<p>
				坚持以客户为中心的服务理念<br>
助力客户加速创新与发展
			</p>
		</div>
		<div class="clearfix">
		</div>
	</div>
</div>

    <!--新闻中心-->
    <div class="xw">
        <div class="commen">
            <div class="bt" data-scroll-reveal="enter bottom and move 30px over 0.3s" data-scroll-reveal-id="9" style="-webkit-transform: translatey(30px);transform: translatey(30px);opacity: 0;-webkit-transition: -webkit-transform 0.3s ease-in-out 0s,  opacity 0.3s ease-in-out 0.1s;transition: transform 0.3s ease-in-out 0s,  opacity 0.3s ease-in-out 0.1s;-webkit-perspective: 1000;-webkit-backface-visibility: hidden;" data-scroll-reveal-initialized="true">
                <img src="img/tt2.png">
            </div>
            <div class="xwa pc" data-scroll-reveal="enter bottom and move 30px over 0.3s" data-scroll-reveal-id="10" style="-webkit-transform: translatey(30px);transform: translatey(30px);opacity: 0;-webkit-transition: -webkit-transform 0.3s ease-in-out 0s,  opacity 0.3s ease-in-out 0.1s;transition: transform 0.3s ease-in-out 0s,  opacity 0.3s ease-in-out 0.1s;-webkit-perspective: 1000;-webkit-backface-visibility: hidden;" data-scroll-reveal-initialized="true">
                <div class="xwb" style="display: none;">
<div class="xwc fl">
<a href="#/news/wangluogongcheng62.html" title="网络工程_青岛网络工程_青岛网络工程公司">
<div class="xwc_t"><img src="img/sdgsgsfg127.jpg" alt="网络工程_青岛网络工程_青岛网络工程公司"></div>
<div class="xwc_w">
<h6>关于网络工程的信息介绍</h6>
<p>说起网络工程，在很多的大学当中都有这个专业的创立。对于网络工程来说，其主要讲授的就是计算机科学基础理论、计算机软硬件系统及应用知识、网络工程的专业知识及应用知识。网络工程的创建，就是旨在培养具有创新意识.</p>
<span>2021-06-30</span>
<img src="img/y25.png">
<div class="clearfix"></div>
</div>
</a>
</div>
<div class="xwc fl">
<a href="#/news/qiyeityunwei61.html" title="企业IT运维_青岛企业it运维的公司">
<div class="xwc_t"><img src="img/dgsdgsg181.jpg" alt="企业IT运维_青岛企业it运维的公司"></div>
<div class="xwc_w">
<h6>企业IT运维包含哪些工作</h6>
<p>企业IT运维一般主要包含有以下八个方面的管理内容：1、设备管理:对网络设备、服务器设备、操作系统运行状况进行监控和管理;2、应用/服务管理:对各种应用支持软件如数据库、中间件、群件以及各种通用或特定服务的监控管理,如邮件系统、DNS、Web 等的监控与管理;</p>
<span>2021-06-23</span>
<img src="img/y25.png">
<div class="clearfix"></div>
</div>
</a>
</div>
<div class="xwc fl">
<a href="#/news/shujuzhongxin60.html" title="数据中心_青岛数据中心_青岛数据中心公司">
<div class="xwc_t"><img src="img/y6.jpg" alt="数据中心_青岛数据中心_青岛数据中心公司"></div>
<div class="xwc_w">
<h6>什么是数据中心</h6>
<p>数据中心(DataCenter)通常是指在一个物理空间内实现信息的集中处理、存储、传输、交换、管理，而计算机设备、服务器设备、网络设备、存储设备等通常认为是网络核心机房的关键设备。</p>
<span>2021-06-16</span>
<img src="img/y25.png">
<div class="clearfix"></div>
</div>
</a>
</div>
<div class="clearfix"></div>
</div>
<div class="xwb" style="display: none;">
<div class="xwc fl">
<a href="#/news/xitongjicheng59.html" title="系统集成_青岛系统集成_青岛系统集成公司">
<div class="xwc_t"><img src="img/zvgsdvbsdv172.jpg" alt="系统集成_青岛系统集成_青岛系统集成公司"></div>
<div class="xwc_w">
<h6>什么是系统集成</h6>
<p>所谓系统集成，就是通过结构化的综合布线系统和计算机网络技术，将各个分离的设备(如个人电脑)、功能和信息等集成到相互关联的、统一和协调的系统之中，使资源达到充分共享，实现集中、高效、便利的管理。系统集成应采用功能集成、网络集成、软件界面集成等多种集成技术。</p>
<span>2021-06-09</span>
<img src="img/y25.png">
<div class="clearfix"></div>
</div>
</a>
</div>
<div class="xwc fl">
<a href="#/news/wangluoanquan58.html" title="网络安全_青岛网络安全_青岛网络安全防护">
<div class="xwc_t"><img src="img/sgsgsg136.jpg" alt="网络安全_青岛网络安全_青岛网络安全防护"></div>
<div class="xwc_w">
<h6>网络安全的过程有哪些</h6>
<p>什么是网络安全?网络安全主要是指用于防止和监控那些没有经过比人授权，就自己访问修改网络可访问的资源。比较通俗的讲就是防止网站数据被其他人恶意的破坏、更改和盗取数据。</p>
<span>2021-06-02</span>
<img src="img/y25.png">
<div class="clearfix"></div>
</div>
</a>
</div>
<div class="xwc fl">
<a href="#/news/wangluogongcheng57.html" title="网络工程哪家好_青岛网络工程哪家公司好">
<div class="xwc_t"><img src="img/sdgsgsfg127.jpg" alt="网络工程哪家好_青岛网络工程哪家公司好"></div>
<div class="xwc_w">
<h6>网络工程涉及的知识领域</h6>
<p>网络工程涉及的知识领域实在是相当的宽广。其实网络工程是一个需要大量知识沉淀的行业，从踏进这个行业开始，就要不停的学习、沉淀、成长，因为要学的东西实在是太多太多了。</p>
<span>2021-05-31</span>
<img src="img/y25.png">
<div class="clearfix"></div>
</div>
</a>
</div>
<div class="clearfix"></div>
</div>
<div class="xwb" style="display: none;">
<div class="xwc fl">
<a href="#/news/qiyeityunwei56.html" title="企业it运维操作_青岛企业it运维操作公司">
<div class="xwc_t"><img src="img/dgsdgsg181.jpg" alt="企业it运维操作_青岛企业it运维操作公司"></div>
<div class="xwc_w">
<h6>企业IT运维的发展趋势</h6>
<p>企业IT运维自动化、智能化.通过打造自动化、智能化的运维管理平台，对数据进行集中管理，对集成层数据、存储层数据以及应用层数据进行智能分析。目前很多产业在运维自动化、智能化的成果还在不断迭代和优化中，它确实能够为运维带来很多的优点和优势</p>
<span>2021-05-28</span>
<img src="img/y25.png">
<div class="clearfix"></div>
</div>
</a>
</div>
<div class="xwc fl">
<a href="#/news/mokuaihuashujuzhongxin55.html" title="模块化数据中心_青岛模块化数据中心公司">
<div class="xwc_t"><img src="img/y6.jpg" alt="模块化数据中心_青岛模块化数据中心公司"></div>
<div class="xwc_w">
<h6>模块化数据中心的应用优势</h6>
<p>模块化数据中心采用模块化、标准化和高可靠性设计，使得整个系统稳定度高，可依据客户需求与实际情况，在供电和制冷核心设备配置上，提供N+1、N+X、2N等设计方案，安全可靠，满足Tier3到Tier4各种等级的标准。</p>
<span>2021-05-27</span>
<img src="img/y25.png">
<div class="clearfix"></div>
</div>
</a>
</div>
<div class="xwc fl">
<a href="#/news/xitongjicheng54.html" title="系统集成供应商_青岛系统集成供应商哪家好">
<div class="xwc_t"><img src="img/zvgsdvbsdv172.jpg" alt="系统集成供应商_青岛系统集成供应商哪家好"></div>
<div class="xwc_w">
<h6>对系统集成的认识误区</h6>
<p>部分物业开发商或业主不能认识到系统集成对物业管理的重要性，而只是为了标榜其物业是“5A建筑”或“智能建筑”而引入系统集成，其思想深处压根就没想利用系统集成进行物业管理。</p>
<span>2021-05-26</span>
<img src="img/y25.png">
<div class="clearfix"></div>
</div>
</a>
</div>
<div class="clearfix"></div>
</div>
<div class="xwb" style="display: block;">
<div class="xwc fl">
<a href="#/news/wangluoanquanxinxihua53.html" title="网络安全和信息化_青岛网络安全和信息化公司">
<div class="xwc_t"><img src="img/sgsgsg136.jpg" alt="网络安全和信息化_青岛网络安全和信息化公司"></div>
<div class="xwc_w">
<h6>为什么网络安全和信息化工作如此重要</h6>
<p>网络安全和信息化首先关乎国家安全。今年年初，美国政府多次以安全为由，不允许美国本土的移动运营商销售华为手机。这是一个号称“自由市场”国家，政府却动不动干预市场规则，为何?害怕华为即将推出的5G网络会威胁他们的数据安全。</p>
<span>2021-05-25</span>
<img src="img/y25.png">
<div class="clearfix"></div>
</div>
</a>
</div>
<div class="xwc fl">
<a href="#/news/anfangjiankonggcs52.html" title="安防监控工程商_青岛安防监控工程商哪家好">
<div class="xwc_t"><img src="img/sdgsgsfg127.jpg" alt="安防监控工程商_青岛安防监控工程商哪家好"></div>
<div class="xwc_w">
<h6>安防监控工程在保障安全中起到的作用</h6>
<p>安防监控工程在生活中比比皆是，这就好像是给生活安装了天眼，让作案人员无处藏身。这些年来，如果人们关注于新闻，必然就会发现有很多小偷小摸的事件，也有高空杂物的事件，这些事件虽然都只是小性质事件，可如果没有制止，很可能会造成较大的隐患.</p>
<span>2021-05-24</span>
<img src="img/y25.png">
<div class="clearfix"></div>
</div>
</a>
</div>
<div class="xwc fl">
<a href="#/news/jisuanjiwangluogongcheng51.html" title="计算机网络工程_青岛计算机网络工程公司">
<div class="xwc_t"><img src="img/dgsdgsg181.jpg" alt="计算机网络工程_青岛计算机网络工程公司"></div>
<div class="xwc_w">
<h6>计算机网络工程的发展现状</h6>
<p>计算机网络工程对工作人员的要求颇高，要求其不仅计算机专业知识扎实，同时还要熟悉所涉及学科的相关知识，如社会学、法学、英语、高等数学等，只有专业化的复合型综合人才，才能胜任计算机网络工程的重担。</p>
<span>2021-05-21</span>
<img src="img/y25.png">
<div class="clearfix"></div>
</div>
</a>
</div>
<div class="clearfix"></div>
</div>

            </div>
            <ul class="xwd" data-scroll-reveal="enter bottom and move 30px over 0.3s" data-scroll-reveal-id="11" style="-webkit-transform: translatey(30px);transform: translatey(30px);opacity: 0;-webkit-transition: -webkit-transform 0.3s ease-in-out 0s,  opacity 0.3s ease-in-out 0.1s;transition: transform 0.3s ease-in-out 0s,  opacity 0.3s ease-in-out 0.1s;-webkit-perspective: 1000;-webkit-backface-visibility: hidden;" data-scroll-reveal-initialized="true">
                <li class=""></li>
<li class=""></li>
<li class=""></li>
<li class="on"></li>

            </ul>
            <div class="xwa mob" data-scroll-reveal="enter bottom and move 30px over 0.3s" data-scroll-reveal-id="12" style="-webkit-transform: translatey(0);transform: translatey(0);opacity: 1;-webkit-transition: -webkit-transform 0.3s ease-in-out 0.1s,  opacity 0.3s ease-in-out 0.1s;transition: transform 0.3s ease-in-out 0.1s, opacity 0.3s ease-in-out 0.1s;-webkit-perspective: 1000;-webkit-backface-visibility: hidden;" data-scroll-reveal-initialized="true">
                <div class="swiper-container swiper-container-horizontal swiper-container-autoheight" id="swiper_xw">
                    <div class="swiper-wrapper" style="transition-duration: 500ms;"><div class="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-active" data-swiper-slide-index="10">
<div class="xwc">
<a href="#/news/anfangjiankonggcs52.html" title="安防监控工程商_青岛安防监控工程商哪家好">
<div class="xwc_t"><img src="img/sdgsgsfg127.jpg" alt="安防监控工程商_青岛安防监控工程商哪家好"></div>
<div class="xwc_w">
<h6>安防监控工程在保障安全中起到的作用</h6>
<p>安防监控工程在生活中比比皆是，这就好像是给生活安装了天眼，让作案人员无处藏身。这些年来，如果人们关注于新闻，必然就会发现有很多小偷小摸的事件，也有高空杂物的事件，这些事件虽然都只是小性质事件，可如果没有制止，很可能会造成较大的隐患.</p>
<span>2021-05-24</span>
<img src="img/y25.png">
<div class="clearfix"></div>
</div>
</a>
</div>
</div><div class="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-active" data-swiper-slide-index="11">
<div class="xwc">
<a href="#/news/jisuanjiwangluogongcheng51.html" title="计算机网络工程_青岛计算机网络工程公司">
<div class="xwc_t"><img src="img/dgsdgsg181.jpg" alt="计算机网络工程_青岛计算机网络工程公司"></div>
<div class="xwc_w">
<h6>计算机网络工程的发展现状</h6>
<p>计算机网络工程对工作人员的要求颇高，要求其不仅计算机专业知识扎实，同时还要熟悉所涉及学科的相关知识，如社会学、法学、英语、高等数学等，只有专业化的复合型综合人才，才能胜任计算机网络工程的重担。</p>
<span>2021-05-21</span>
<img src="img/y25.png">
<div class="clearfix"></div>
</div>
</a>
</div>
</div>
                        <div class="swiper-slide" data-swiper-slide-index="0">
<div class="xwc">
<a href="#/news/wangluogongcheng62.html" title="网络工程_青岛网络工程_青岛网络工程公司">
<div class="xwc_t"><img src="img/sdgsgsfg127.jpg" alt="网络工程_青岛网络工程_青岛网络工程公司"></div>
<div class="xwc_w">
<h6>关于网络工程的信息介绍</h6>
<p>说起网络工程，在很多的大学当中都有这个专业的创立。对于网络工程来说，其主要讲授的就是计算机科学基础理论、计算机软硬件系统及应用知识、网络工程的专业知识及应用知识。网络工程的创建，就是旨在培养具有创新意识.</p>
<span>2021-06-30</span>
<img src="img/y25.png">
<div class="clearfix"></div>
</div>
</a>
</div>
</div>
<div class="swiper-slide" data-swiper-slide-index="1">
<div class="xwc">
<a href="#/news/qiyeityunwei61.html" title="企业IT运维_青岛企业it运维的公司">
<div class="xwc_t"><img src="img/dgsdgsg181.jpg" alt="企业IT运维_青岛企业it运维的公司"></div>
<div class="xwc_w">
<h6>企业IT运维包含哪些工作</h6>
<p>企业IT运维一般主要包含有以下八个方面的管理内容：1、设备管理:对网络设备、服务器设备、操作系统运行状况进行监控和管理;2、应用/服务管理:对各种应用支持软件如数据库、中间件、群件以及各种通用或特定服务的监控管理,如邮件系统、DNS、Web 等的监控与管理;</p>
<span>2021-06-23</span>
<img src="img/y25.png">
<div class="clearfix"></div>
</div>
</a>
</div>
</div>
<div class="swiper-slide" data-swiper-slide-index="2">
<div class="xwc">
<a href="#/news/shujuzhongxin60.html" title="数据中心_青岛数据中心_青岛数据中心公司">
<div class="xwc_t"><img src="img/y6.jpg" alt="数据中心_青岛数据中心_青岛数据中心公司"></div>
<div class="xwc_w">
<h6>什么是数据中心</h6>
<p>数据中心(DataCenter)通常是指在一个物理空间内实现信息的集中处理、存储、传输、交换、管理，而计算机设备、服务器设备、网络设备、存储设备等通常认为是网络核心机房的关键设备。</p>
<span>2021-06-16</span>
<img src="img/y25.png">
<div class="clearfix"></div>
</div>
</a>
</div>
</div>
<div class="swiper-slide" data-swiper-slide-index="3">
<div class="xwc">
<a href="#/news/xitongjicheng59.html" title="系统集成_青岛系统集成_青岛系统集成公司">
<div class="xwc_t"><img src="img/zvgsdvbsdv172.jpg" alt="系统集成_青岛系统集成_青岛系统集成公司"></div>
<div class="xwc_w">
<h6>什么是系统集成</h6>
<p>所谓系统集成，就是通过结构化的综合布线系统和计算机网络技术，将各个分离的设备(如个人电脑)、功能和信息等集成到相互关联的、统一和协调的系统之中，使资源达到充分共享，实现集中、高效、便利的管理。系统集成应采用功能集成、网络集成、软件界面集成等多种集成技术。</p>
<span>2021-06-09</span>
<img src="img/y25.png">
<div class="clearfix"></div>
</div>
</a>
</div>
</div>
<div class="swiper-slide" data-swiper-slide-index="4">
<div class="xwc">
<a href="#/news/wangluoanquan58.html" title="网络安全_青岛网络安全_青岛网络安全防护">
<div class="xwc_t"><img src="img/sgsgsg136.jpg" alt="网络安全_青岛网络安全_青岛网络安全防护"></div>
<div class="xwc_w">
<h6>网络安全的过程有哪些</h6>
<p>什么是网络安全?网络安全主要是指用于防止和监控那些没有经过比人授权，就自己访问修改网络可访问的资源。比较通俗的讲就是防止网站数据被其他人恶意的破坏、更改和盗取数据。</p>
<span>2021-06-02</span>
<img src="img/y25.png">
<div class="clearfix"></div>
</div>
</a>
</div>
</div>
<div class="swiper-slide" data-swiper-slide-index="5">
<div class="xwc">
<a href="#/news/wangluogongcheng57.html" title="网络工程哪家好_青岛网络工程哪家公司好">
<div class="xwc_t"><img src="img/sdgsgsfg127.jpg" alt="网络工程哪家好_青岛网络工程哪家公司好"></div>
<div class="xwc_w">
<h6>网络工程涉及的知识领域</h6>
<p>网络工程涉及的知识领域实在是相当的宽广。其实网络工程是一个需要大量知识沉淀的行业，从踏进这个行业开始，就要不停的学习、沉淀、成长，因为要学的东西实在是太多太多了。</p>
<span>2021-05-31</span>
<img src="img/y25.png">
<div class="clearfix"></div>
</div>
</a>
</div>
</div>
<div class="swiper-slide" data-swiper-slide-index="6">
<div class="xwc">
<a href="#/news/qiyeityunwei56.html" title="企业it运维操作_青岛企业it运维操作公司">
<div class="xwc_t"><img src="img/dgsdgsg181.jpg" alt="企业it运维操作_青岛企业it运维操作公司"></div>
<div class="xwc_w">
<h6>企业IT运维的发展趋势</h6>
<p>企业IT运维自动化、智能化.通过打造自动化、智能化的运维管理平台，对数据进行集中管理，对集成层数据、存储层数据以及应用层数据进行智能分析。目前很多产业在运维自动化、智能化的成果还在不断迭代和优化中，它确实能够为运维带来很多的优点和优势</p>
<span>2021-05-28</span>
<img src="img/y25.png">
<div class="clearfix"></div>
</div>
</a>
</div>
</div>
<div class="swiper-slide" data-swiper-slide-index="7">
<div class="xwc">
<a href="#/news/mokuaihuashujuzhongxin55.html" title="模块化数据中心_青岛模块化数据中心公司">
<div class="xwc_t"><img src="img/y6.jpg" alt="模块化数据中心_青岛模块化数据中心公司"></div>
<div class="xwc_w">
<h6>模块化数据中心的应用优势</h6>
<p>模块化数据中心采用模块化、标准化和高可靠性设计，使得整个系统稳定度高，可依据客户需求与实际情况，在供电和制冷核心设备配置上，提供N+1、N+X、2N等设计方案，安全可靠，满足Tier3到Tier4各种等级的标准。</p>
<span>2021-05-27</span>
<img src="img/y25.png">
<div class="clearfix"></div>
</div>
</a>
</div>
</div>
<div class="swiper-slide" data-swiper-slide-index="8">
<div class="xwc">
<a href="#/news/xitongjicheng54.html" title="系统集成供应商_青岛系统集成供应商哪家好">
<div class="xwc_t"><img src="img/zvgsdvbsdv172.jpg" alt="系统集成供应商_青岛系统集成供应商哪家好"></div>
<div class="xwc_w">
<h6>对系统集成的认识误区</h6>
<p>部分物业开发商或业主不能认识到系统集成对物业管理的重要性，而只是为了标榜其物业是“5A建筑”或“智能建筑”而引入系统集成，其思想深处压根就没想利用系统集成进行物业管理。</p>
<span>2021-05-26</span>
<img src="img/y25.png">
<div class="clearfix"></div>
</div>
</a>
</div>
</div>
<div class="swiper-slide" data-swiper-slide-index="9">
<div class="xwc">
<a href="#/news/wangluoanquanxinxihua53.html" title="网络安全和信息化_青岛网络安全和信息化公司">
<div class="xwc_t"><img src="img/sgsgsg136.jpg" alt="网络安全和信息化_青岛网络安全和信息化公司"></div>
<div class="xwc_w">
<h6>为什么网络安全和信息化工作如此重要</h6>
<p>网络安全和信息化首先关乎国家安全。今年年初，美国政府多次以安全为由，不允许美国本土的移动运营商销售华为手机。这是一个号称“自由市场”国家，政府却动不动干预市场规则，为何?害怕华为即将推出的5G网络会威胁他们的数据安全。</p>
<span>2021-05-25</span>
<img src="img/y25.png">
<div class="clearfix"></div>
</div>
</a>
</div>
</div>
<div class="swiper-slide" data-swiper-slide-index="10">
<div class="xwc">
<a href="#/news/anfangjiankonggcs52.html" title="安防监控工程商_青岛安防监控工程商哪家好">
<div class="xwc_t"><img src="img/sdgsgsfg127.jpg" alt="安防监控工程商_青岛安防监控工程商哪家好"></div>
<div class="xwc_w">
<h6>安防监控工程在保障安全中起到的作用</h6>
<p>安防监控工程在生活中比比皆是，这就好像是给生活安装了天眼，让作案人员无处藏身。这些年来，如果人们关注于新闻，必然就会发现有很多小偷小摸的事件，也有高空杂物的事件，这些事件虽然都只是小性质事件，可如果没有制止，很可能会造成较大的隐患.</p>
<span>2021-05-24</span>
<img src="img/y25.png">
<div class="clearfix"></div>
</div>
</a>
</div>
</div>
<div class="swiper-slide" data-swiper-slide-index="11">
<div class="xwc">
<a href="#/news/jisuanjiwangluogongcheng51.html" title="计算机网络工程_青岛计算机网络工程公司">
<div class="xwc_t"><img src="img/dgsdgsg181.jpg" alt="计算机网络工程_青岛计算机网络工程公司"></div>
<div class="xwc_w">
<h6>计算机网络工程的发展现状</h6>
<p>计算机网络工程对工作人员的要求颇高，要求其不仅计算机专业知识扎实，同时还要熟悉所涉及学科的相关知识，如社会学、法学、英语、高等数学等，只有专业化的复合型综合人才，才能胜任计算机网络工程的重担。</p>
<span>2021-05-21</span>
<img src="img/y25.png">
<div class="clearfix"></div>
</div>
</a>
</div>
</div>

                    <div class="swiper-slide swiper-slide-duplicate" data-swiper-slide-index="0">
<div class="xwc">
<a href="#/news/wangluogongcheng62.html" title="网络工程_青岛网络工程_青岛网络工程公司">
<div class="xwc_t"><img src="img/sdgsgsfg127.jpg" alt="网络工程_青岛网络工程_青岛网络工程公司"></div>
<div class="xwc_w">
<h6>关于网络工程的信息介绍</h6>
<p>说起网络工程，在很多的大学当中都有这个专业的创立。对于网络工程来说，其主要讲授的就是计算机科学基础理论、计算机软硬件系统及应用知识、网络工程的专业知识及应用知识。网络工程的创建，就是旨在培养具有创新意识.</p>
<span>2021-06-30</span>
<img src="img/y25.png">
<div class="clearfix"></div>
</div>
</a>
</div>
</div><div class="swiper-slide swiper-slide-duplicate" data-swiper-slide-index="1">
<div class="xwc">
<a href="#/news/qiyeityunwei61.html" title="企业IT运维_青岛企业it运维的公司">
<div class="xwc_t"><img src="img/dgsdgsg181.jpg" alt="企业IT运维_青岛企业it运维的公司"></div>
<div class="xwc_w">
<h6>企业IT运维包含哪些工作</h6>
<p>企业IT运维一般主要包含有以下八个方面的管理内容：1、设备管理:对网络设备、服务器设备、操作系统运行状况进行监控和管理;2、应用/服务管理:对各种应用支持软件如数据库、中间件、群件以及各种通用或特定服务的监控管理,如邮件系统、DNS、Web 等的监控与管理;</p>
<span>2021-06-23</span>
<img src="img/y25.png">
<div class="clearfix"></div>
</div>
</a>
</div>
</div></div>
                <span class="swiper-notification" aria-live="assertive" aria-atomic="true"></span></div>
            </div>
            <!-- <div class="ywa_m xw_m" data-scroll-reveal="enter bottom and move 20px over 0.2s" data-scroll-reveal-id="13" style="-webkit-transform: translatey(20px);transform: translatey(20px);opacity: 0;-webkit-transition: -webkit-transform 0.2s ease-in-out 0s,  opacity 0.2s ease-in-out 0.1s;transition: transform 0.2s ease-in-out 0s,  opacity 0.2s ease-in-out 0.1s;-webkit-perspective: 1000;-webkit-backface-visibility: hidden;" data-scroll-reveal-initialized="true">
                <a href="#/news/">查看更多 &gt;</a></div> -->
        </div>
    </div>

    <!--合作客户-->
    <div class="hz">
        <div class="commen">
            <!-- <div class="bt" data-scroll-reveal="enter bottom and move 30px over 0.3s" data-scroll-reveal-id="14" style="-webkit-transform: translatey(30px);transform: translatey(30px);opacity: 0;-webkit-transition: -webkit-transform 0.3s ease-in-out 0s,  opacity 0.3s ease-in-out 0.1s;transition: transform 0.3s ease-in-out 0s,  opacity 0.3s ease-in-out 0.1s;-webkit-perspective: 1000;-webkit-backface-visibility: hidden;" data-scroll-reveal-initialized="true">
                <img src="img/tt3.png">
            </div>
            <div class="hza" data-scroll-reveal="enter bottom and move 30px over 0.3s" data-scroll-reveal-id="15" style="-webkit-transform: translatey(30px);transform: translatey(30px);opacity: 0;-webkit-transition: -webkit-transform 0.3s ease-in-out 0s,  opacity 0.3s ease-in-out 0.1s;transition: transform 0.3s ease-in-out 0s,  opacity 0.3s ease-in-out 0.1s;-webkit-perspective: 1000;-webkit-backface-visibility: hidden;" data-scroll-reveal-initialized="true">
	<div class="hzb" style="display: none;">
		<div class="hzc fl">
			<img src="img/20210303084508_3662.jpg"> 
		</div>
		<div class="hzc fl">
			<img src="img/20210303084603_7255.jpg"> 
		</div>
		<div class="hzc fl">
			<img src="img/20210303084644_3349.jpg"> 
		</div>
		<div class="hzc fl">
			<img src="img/20210303084923_8037.jpg"> 
		</div>
		<div class="hzc fl">
			<img src="img/20210303084949_3505.jpg"> 
		</div>
		<div class="hzc fl">
			<img src="img/20210303085026_3896.jpg"> 
		</div>
		<div class="hzc fl">
			<img src="img/20210303085056_5771.jpg"> 
		</div>
		<div class="hzc fl">
			<img src="img/20210303085133_1162.jpg"> 
		</div>
		<div class="hzc fl">
			<img src="img/20210303085153_7880.jpg"> 
		</div>
		<div class="hzc fl">
			<img src="img/20210303085220_0849.jpg"> 
		</div>
		<div class="clearfix">
		</div>
	</div>
	<div class="hzb" style="display: block;">
		<div class="hzc fl">
			<img src="img/20210303085241_3896.jpg"> 
		</div>
		<div class="hzc fl">
			<img src="img/20210303085304_6474.jpg"> 
		</div>
		<div class="hzc fl">
			<img src="img/20210303085326_8193.jpg"> 
		</div>
		<div class="hzc fl">
			<img src="img/20210303085347_7177.jpg"> 
		</div>
		<div class="hzc fl">
			<img src="img/20210303085412_2490.jpg"> 
		</div>
		<div class="hzc fl">
			<img src="img/20210303085438_6240.jpg"> 
		</div>
		<div class="hzc fl">
			<img src="img/20210303085502_8037.jpg"> 
		</div>
		<div class="hzc fl">
			<img src="img/20210303085529_8896.jpg"> 
		</div>
		<div class="hzc fl">
			<img src="img/20210303085551_6787.jpg"> 
		</div>
		<div class="hzc fl">
			<img src="img/20210303085618_6474.jpg"> 
		</div>
		<div class="clearfix">
		</div>
	</div>
	<div class="hzb" style="display: none;">
		<div class="hzc fl">
			<img src="img/20210303085647_2177.jpg"> 
		</div>
		<div class="hzc fl">
			<img src="img/20210303085713_7802.jpg"> 
		</div>
		<div class="hzc fl">
			<img src="img/20210303085741_1083.jpg"> 
		</div>
		<div class="hzc fl">
			<img src="img/20210303085805_5849.jpg"> 
		</div>
		<div class="hzc fl">
			<img src="img/20210303085841_2099.jpg"> 
		</div>
		<div class="hzc fl">
			<img src="img/20210303085902_7490.jpg"> 
		</div>
		<div class="hzc fl">
			<img src="img/20210303085924_9443.jpg"> 
		</div>
		<div class="hzc fl">
			<img src="img/20210303085955_9755.jpg"> 
		</div>
		<div class="hzc fl">
			<img src="img/20210303090016_3818.jpg"> 
		</div>
		<div class="hzc fl">
			<img src="img/20210303090037_6708.jpg"> 
		</div>
		<div class="clearfix">
		</div>
	</div>
</div>
<ul class="hzd" data-scroll-reveal="enter bottom and move 30px over 0.3s" data-scroll-reveal-id="16" style="-webkit-transform: translatey(30px);transform: translatey(30px);opacity: 0;-webkit-transition: -webkit-transform 0.3s ease-in-out 0s,  opacity 0.3s ease-in-out 0.1s;transition: transform 0.3s ease-in-out 0s,  opacity 0.3s ease-in-out 0.1s;-webkit-perspective: 1000;-webkit-backface-visibility: hidden;" data-scroll-reveal-initialized="true">
	<li class="">
	</li>
	<li class="on">
	</li>
	<li class="">
	</li>
</ul> -->
            <!-- <div class="ywa_m hz_m" data-scroll-reveal="enter bottom and move 20px over 0.2s" data-scroll-reveal-id="17" style="-webkit-transform: translatey(20px);transform: translatey(20px);opacity: 0;-webkit-transition: -webkit-transform 0.2s ease-in-out 0s,  opacity 0.2s ease-in-out 0.1s;transition: transform 0.2s ease-in-out 0s,  opacity 0.2s ease-in-out 0.1s;-webkit-perspective: 1000;-webkit-backface-visibility: hidden;" data-scroll-reveal-initialized="true">
                <a href="#/yuancheng/partner.html">查看更多 &gt;</a></div> -->
        </div>
    </div>


  </div>
</template>

<script>
// import { getList } from "@/api/index";

export default {
  data() {
    return {
      // imgU1: require("@img/u1.png"),

      menuList: [],
    };
  },
  created() {
    // getList().then( res => {
    //   console.log(res);
    //   this.menuList = res.rows
    // })
  },
  mounted() {
    require("@/assets/js/yc.js")
    $(function () {
      $(".yb_conct").hover(
        function () {
          $(".yb_conct").css("right", "5px");
          $(".yb_bar .yb_ercode").css("height", "190px");
        },
        function () {
          $(".yb_conct").css("right", "-127px");
          $(".yb_bar .yb_ercode").css("height", "53px");
        }
      );
      $(".yb_top").click(function () {
        $("html,body").animate(
          {
            scrollTop: "0px",
          },
          300
        );
      });
    });

    (function ($) {
      "use strict";
      window.scrollReveal = new scrollReveal({ reset: true, move: "50px" });
    })();

    var swiper2 = new Swiper(".swiper2", {
      autoplay: {
        delay: 6000,
        disableOnInteraction: false,
      },
      speed: 600, //切换速度
      autoHeight: true, //高度随内容变化
      loop: true,
    });

    var swiper_yw = new Swiper("#swiper_yw", {
      autoplay: {
        delay: 8000,
        disableOnInteraction: false,
      },
      speed: 500, //切换速度
      autoHeight: true, //高度随内容变化
      loop: true,
      spaceBetween: "2%",
      slidesPerView: 2,
    });

    var swiper_xw = new Swiper("#swiper_xw", {
      autoplay: {
        delay: 8000,
        disableOnInteraction: false,
      },
      speed: 500, //切换速度
      autoHeight: true, //高度随内容变化
      loop: true,
      spaceBetween: "2%",
      slidesPerView: 2,
    });
  },
};
</script>

<style scoped>
@import "~@/assets/style/yc.css";
@import "~@/assets/style/swiper.min.css";
@import "~@/assets/style/animate.min.css";
</style>