// JavaScript Document



$(document).ready(function(){
	$(".topa_aa").mouseenter(function(){
		$(this).siblings(".t1").stop(true,true).css("opacity","1");
	});
	$(".topa_aa").mouseleave(function(){
		$(this).siblings(".t1").stop(true,true).css("opacity","0");
	});
});

//轮播图
$(document).ready(function(){
	var fv = 0
	var gs = $(".imgbox img").length;
//	var m =$(".imgbox img").index+1;
	$(".imgbox img").hide().first().fadeIn();
	$(".flip li").click(function(){
		$(this).addClass("ppp").siblings().removeClass("ppp");
		fv = $(this).index();
		$(".imgbox img").fadeOut().eq(fv).fadeIn();
	});
	//点击右箭头
	$(".bt-f").click(function(){
		if(fv<gs-1){
			fv=fv+1;
		}else{
			fv=0;
		}
		$(".imgbox img").fadeOut().eq(fv).fadeIn();
		$(".flip li").eq(fv).addClass("ppp").siblings().removeClass("ppp");
		$(".bt-f").addClass("boya");
		$(".bt-b").removeClass("boya");
	});
	//点击左箭头
	$(".bt-b").click(function(){
		if(fv>0){
			fv=fv-1;
		}else{
			fv=0;
		}
		$(".imgbox img").fadeOut().eq(fv).fadeIn();
		$(".flip li").eq(fv).addClass("ppp").siblings().removeClass("ppp");
		$(".bt-b").addClass("boya");
		$(".bt-f").removeClass("boya");
	});
	//自动轮播
	setInterval(function(){
		if(fv<gs-1){
			fv=fv+1;
		}else{
			fv=0;
		}
		$(".imgbox img").fadeOut().eq(fv).fadeIn();
		$(".flip li").eq(fv).addClass("ppp").siblings().removeClass("ppp");
	},5000);
	$(".bt-a").hide();
	$(".bt-k").hide();
	$(".ban").mouseenter(function(){
		$(".bt-a").fadeIn();
		$(".bt-k").fadeIn();
	});
	$(".ban").mouseleave(function(){
		$(".bt-a").fadeOut();
		$(".bt-k").fadeOut();
	});
});



//新闻切换
$(document).ready(function(){
	var fv = 0
	var gs = $(".xwb").length;
	$(".xwb").hide().eq(0).show()
	$(".xwd li").click(function(){
		$(".xwb").hide().eq($(this).index()).fadeIn();
		$(this).addClass("on").siblings().removeClass("on");
	});
	//自动轮播
	setInterval(function(){
		if(fv<gs-1){
			fv=fv+1;
		}else{
			fv=0;
		}
		$(".xwb").hide().eq(fv).fadeIn();
		$(".xwd li").eq(fv).addClass("on").siblings().removeClass("on");
	},6000);
});

//合作客户切换
$(document).ready(function(){
	var fv = 0
	var gs = $(".hzb").length;
	$(".hzb").hide().eq(0).show()
	$(".hzd li").click(function(){
		$(".hzb").hide().eq($(this).index()).fadeIn();
		$(this).addClass("on").siblings().removeClass("on");
	});
	//自动轮播
	setInterval(function(){
		if(fv<gs-1){
			fv=fv+1;
		}else{
			fv=0;
		}
		$(".hzb").hide().eq(fv).fadeIn();
		$(".hzd li").eq(fv).addClass("on").siblings().removeClass("on");
	},6000);
});
















//荣誉切换
$(document).ready(function(){
	var fv = 0
	var gs = $(".ryycb").length;
	$(".ryycb").hide().first().fadeIn();
	$(".ryyca_a li").click(function(){
		$(this).addClass("on").siblings().removeClass("on");
		fv = $(this).index();
		$(".ryycb").hide().eq(fv).fadeIn();
	});
	//自动轮播
	setInterval(function(){
		if(fv<gs-1){
			fv=fv+1;
		}else{
			fv=0;
		}
		$(".ryycb").hide().eq(fv).fadeIn();
		$(".ryyca_a li").eq(fv).addClass("on").siblings().removeClass("on");
	},5000);

});

//社会公益
$(document).ready(function(){
	var fv = 0
	var gs = $(".gyyba img").length;
	$(".gyyba img").hide().first().fadeIn();
	$(".gyybb li").click(function(){
		$(this).addClass("on").siblings().removeClass("on");
		fv = $(this).index();
		$(".gyyba img").hide().eq(fv).fadeIn();
	});
	//自动轮播
	setInterval(function(){
		if(fv<gs-1){
			fv=fv+1;
		}else{
			fv=0;
		}
		$(".gyyba img").hide().eq(fv).fadeIn();
		$(".gyybb li").eq(fv).addClass("on").siblings().removeClass("on");
	},5000);

});



//应用领域切换
$(document).ready(function(){
	var fv = 0
	var gs = $(".cpndb").length;
	$(".cpndb").hide().first().fadeIn();
	$(".cpndx li").click(function(){
		$(this).addClass("on").siblings().removeClass("on");
		fv = $(this).index();
		$(".cpndb").hide().eq(fv).fadeIn();
	});
	//自动轮播
	setInterval(function(){
		if(fv<gs-1){
			fv=fv+1;
		}else{
			fv=0;
		}
		$(".cpndb").hide().eq(fv).fadeIn();
		$(".cpndx li").eq(fv).addClass("on").siblings().removeClass("on");
	},5000);

});































//热销产品
$(document).ready(function(){
	var n = 0
	var b=$(".rxcpb").length;
	var o=$(".rxcp").width();
	var c=$(".rxcpa").width(o*b/4);
	var a=$(".rxcpb").width(b/4*o/b);
	//点击右箭头
	$(".rxcp_y").click(function(){
		if(n<b-4){
			n++;
		}else{
			n=0;}
		$(".rxcpa").animate({marginLeft: -b/4*o/b*n},500,"easeInOutCubic");
	});
	//点击左箭头
	$(".rxcp_z").click(function(){
		if(n>0){
			n=n-1;
		}else{
			n=0;}
		$(".rxcpa").animate({marginLeft: -b/4*o/b*n},500,"easeInOutCubic");
	});
//	自动轮播
	var timer=setInterval(picSet,6000);
    function picSet(){
	    if(n<b-4){n=n+1
		}else{n=0}
		$(".rxcpa").animate({marginLeft: -b/4*o/b*n},500);
	}
});








//产品内页切换



//常见问答
$(document).ready(function(){
	$(".cja_d").slideUp()
	$(".cja").click(function(){
		$(this).children(".cja_d").stop(true,true).slideDown(200);
		$(this).addClass("cjaa");
		$(this).siblings().children(".cja_d").stop(true,true).slideUp();
		$(this).siblings().removeClass("cjaa");
	});
});




//荣誉资质切换
$(document).ready(function(){
	var fv = 0
	var gs = $(".ryba").length;
	$(".ryba").hide().first().fadeIn();
	$(".ryc li").click(function(){
		$(this).addClass("on").siblings().removeClass("on");
		fv = $(this).index();
		$(".ryba").hide().eq(fv).fadeIn();
	});
	//自动轮播
	setInterval(function(){
		if(fv<gs-1){
			fv=fv+1;
		}else{
			fv=0;
		}
		$(".ryba").hide().eq(fv).fadeIn();
		$(".ryc li").eq(fv).addClass("on").siblings().removeClass("on");
	},5000);
});


















//右侧浮窗
$(function(){
	var din = $(".danpo , .dinr").hide()
	$(".xain").mouseenter(function(){
		din.show();
	});
	$(".xain").mouseleave(function(){
		din.hide();
	});
	var sdc = $(".zung , .dingw").hide();
	$(".daoma").mouseenter(function(){
		sdc.show();
	});
	$(".daoma").mouseleave(function(){
		sdc.hide();
	});
	var zhao = $(".libai , .weic").hide();
	$(".topb").mouseenter(function(){
		zhao.show();
	});
	$(".topb").mouseleave(function(){
		zhao.hide();
	});
});
$(document).ready(function(){
	$(".fhtop").click(function(){
		$("html,body").animate({scrollTop:0},"slow");
	});
});
$(document).ready(function(){
	$(".fhtop").click(function(){
		$("html,body").animate({scrollTop:0},"slow");
	});
});
























